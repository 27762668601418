interface IProps {
  announcement: string;
}

export const AnnouncementBanner = ({ announcement }: IProps) => {
  return (
    <div
      className={
        "flex relative h-32 w-full m-0 p-10 items-center justify-center overflow-hidden bg-red-400"
      }
    >
      <p className={"text-xl font-bold"}>{announcement}</p>
    </div>
  );
};
