import { BUSINESS_NAME } from "../constants/constants";
import image from "./../images/CactusCake.jpg";

const TAGLINE = "The best cakes this side of Lake Michigan";
const DESCRIPTION =
  "I am the very bestest cakemaker that has ever lived in Wisconsin. My dog Charlie and I bake cakes religously. The cakes are good.";
const BUTTON = "View Gallery";

export const HeroRight = () => {
  return (
    <section
      className={"relative pt-32 px-4 pb-4 text-center bg-background2-200"}
    >
      <div className="flex flex-col lg:flex-row max-w-[100rem] items-center justify-between m-auto gap-20">
        <div className="flex flex-col w-full items-center justify-center text-center lg:text-left lg:order-2">
          <span className="w-full text-inherit uppercase font-bold text-secondary-200">
            {TAGLINE}
          </span>
          <h1 className="w-full m-0 text-8xl font-bold text-primary-700">
            {BUSINESS_NAME}
          </h1>
          <p className="w-full mt-4 text-4xl text-primary-500">{DESCRIPTION}</p>
          <p className="relative inline-block min-w-fit mt-10 p-4 self-center lg:self-start text-center rounded-md font-semibold text-dark-500 bg-secondary-200 hover:bg-secondary-100">
            {BUTTON}
          </p>
        </div>

        <picture
          className={"block relative w-full max-w-[36rem] z-10 lg:order-1"}
        >
          <img
            className={
              "block h-full w-full min-h-[50rem] object-cover object-top aspect-auto rounded-lg rounded-tl-[12.5rem] rounded-br-[12.5rem] shadow-lg"
            }
            decoding="async"
            src={image}
            alt="plank"
          />
        </picture>
      </div>

      <svg
        className="block absolute left-0 bottom-[-2px] w-[100%] h-auto"
        viewBox="0 0 1920 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1920 179V91.3463C1835.33 91.3463 1715.47 76.615 1549.2 32.9521C1299.48 -32.3214 1132.77 12.1006 947.32 61.5167C810.762 97.9044 664.042 137 466.533 137C331.607 137 256.468 123.447 188.082 111.113C130.974 100.812 78.5746 91.3609 0 91.3609V179H1920Z"
          fill="white"
        />
      </svg>
    </section>
  );
};
