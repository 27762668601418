import { IconCard } from "../components/IconCard";
import cake from "./../images/PurpleBallCake.jpg";
import pops from "./../images/CakePops.jpg";
import cupcake from "./../images/SwirledCupcakes.jpg";
import cinroll from "./../images/CinRoll.jpg";

const TITLE = "Our Products";
const TAGLINE = "High Quality Cakes";
const DESCRIPTION =
  "I bake all of my cakes from scratch. No boxed mix, no tubs of icing, everything is done by hand with love.";

export const ServicesInline = () => {
  return (
    <section className={"relative bg-background1-200"}>
      <svg
        className="translate-y-[-2px] rotate-180"
        viewBox="0 0 1920 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1920 179V91.3463C1835.33 91.3463 1715.47 76.615 1549.2 32.9521C1299.48 -32.3214 1132.77 12.1006 947.32 61.5167C810.762 97.9044 664.042 137 466.533 137C331.607 137 256.468 123.447 188.082 111.113C130.974 100.812 78.5746 91.3609 0 91.3609V179H1920Z"
          fill="white"
        />
      </svg>
      <div className="flex flex-col w-full max-w-[160rem] items-center justify-center m-auto px-6 py-8 gap-[4rem]">
        <div className="flex flex-row w-full max-w-[80rem] text-left items-center justify-between gap-4">
          <div className="block box-border">
            <span className="block mb-1 uppercase font-bold text-2xl text-secondary-600">
              {TITLE}
            </span>
            <h2 className="relative max-w-[44rem] font-bold text-xl text-primary-600">
              {TAGLINE}
            </h2>
          </div>
          <p className="w-full max-w-[40rem] m-0 text-lg text-secondary-200">
            {DESCRIPTION}
          </p>
        </div>
      </div>
      <ul
        className={
          "grid grid-cols-2 lg:grid-cols-4 w-full h-full min-h-[40rem] p-0 m-0"
        }
      >
        <IconCard title={"CAKES"} image={cake} />
        <IconCard title={"CUP CAKES"} image={cupcake} />
        <IconCard title={"CAKE POPS"} image={pops} />
        <IconCard title={"PASTRIES"} image={cinroll} />
      </ul>
    </section>
  );
};
