import "./App.css";
import { AnnouncementBanner } from "./components/AnnouncementBanner";
import { HeroRight } from "./sections/HeroRight";
import { ServicesInline } from "./sections/ServicesInline";
import { SimpleFooter } from "./sections/SimpleFooter";

function App() {
  return (
    <>
      <AnnouncementBanner
        announcement={
          "Please be patient, our site is currently under construction"
        }
      />
      <HeroRight />
      <ServicesInline />
      <SimpleFooter />
    </>
  );
}

export default App;
