interface IProps {
  title: string;
  image: string;
}

export const IconCard = ({ title, image }: IProps) => {
  return (
    <div
      className={
        "flex flex-col relative h-full max-h-[30rem] w-full m-0 p-0 overflow-hidden"
      }
    >
      <p className={"flex flex-col relative h-full w-full justify-end p-8"}>
        <h3
          className={
            "block w-[80%] overflow-hidden text-2xl font-extrabold text-dark-500 z-[1]"
          }
        >
          {title}
        </h3>
      </p>
      <picture
        className={
          "block absolute w-full h-full top-0 left-0 lg:transition-transform lg:duration-75 lg:ease-in-out lg:hover:scale-110 lg:hover:opacity-50"
        }
      >
        <img
          src={image}
          alt={"card"}
          className={"w-full h-full absolute top-0 left-0 z-0 object-cover"}
        />
      </picture>
    </div>
  );
};
