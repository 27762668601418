import { BUSINESS_NAME } from "../constants/constants";
import { CONTACT_INFO } from "../constants/constants";

import logo from "./../images/FirstCake.png";
import google from "./../icons/Google.svg";
import face from "./../icons/Facebook.svg";
import insta from "./../icons/Instagram.svg";

export const SimpleFooter = () => {
  return (
    <footer className={"bg-background3-600"}>
      <div
        className={
          "flex flex-col lg:flex-row flex-wrap relative justify-between content-center w-full p-32 text-xl text-primary-800"
        }
      >
        <div className={"items-center mt-10"}>
          <p className={"flex h-32 w-32"}>
            <img src={logo} alt={"logo"} className={"w-full h-auto"} />
          </p>
          <p>{BUSINESS_NAME}</p>
        </div>
        <div className={"mt-10"}>
          <span className={"font-medium text-3xl text-secondary-600"}>
            Sitemap
          </span>
          <ul className={"mt-4"}>
            <li>Home</li>
            <li>Products</li>
            <li>Gallery</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className={"flex flex-col items-center mt-10"}>
          <div>
            <span className={"font-medium text-3xl text-secondary-600"}>
              Contact
            </span>
            <ul className={"mt-4"}>
              {/* <li>
                <p>{CONTACT_INFO.PHONE}</p>
              </li> */}
              <li>
                <p>{CONTACT_INFO.EMAIL}</p>
              </li>
            </ul>
          </div>
          <ul className={"flex flex-row w-full justify-between mt-8"}>
            <li>
              <img src={face} alt={"facebook"} />
            </li>
            <li>
              <img src={insta} alt={"instagram"} />
            </li>
            <li>
              <img src={google} alt={"google"} />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
