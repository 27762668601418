export const BUSINESS_NAME = "Charlie's Cakes";

export const CONTACT_INFO = {
  PHONE: "847.693.9366",
  EMAIL: "kristen.taylor017@gmail",
};

export const SOCIAL_MEDIA = {
  FACEBOOK: "",
  INSTAGRAM: "",
  GOOGLE: "",
};
